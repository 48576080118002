import React from "react"
import { nameToId } from "../utils"

import { INodeProperties, ITableOfContents } from "../types"

const renderHtmlAst = (node, language, toc) => {
  let children = []

  if (node.children) {
    for (let childNode of node.children) {
      let element = renderHtmlAst(childNode, language, toc)
      if (element) {
        children.push(element)
      }
    }
  }

  let element = null
  let headingRef = React.createRef<HTMLElement>()
  let isHeading = false

  if (node.type === "comment") {
    element = null
  }
  if (node.type === "text") {
    element = node.value
  }
  if (node.type === "root" || node.type === "element") {
    let properties: INodeProperties = {}

    if (node.properties) {
      properties = {
        ...node.properties,
        className:
          node.properties.className &&
          node.properties.className instanceof Array
            ? node.properties.className.join(" ")
            : node.properties.className,
      }

      if (properties.className === "gatsby-highlight") {
        if (
          properties.dataLanguage !== language &&
          properties.dataLanguage !== "json"
        ) {
          properties.style = {
            display: "none",
          }
        }
      }
    }

    if (node.type === "root") {
      properties.className = "content"
    }

    else if (node.type === "element" && node.tagName === "h1") {
      toc.push({
        heading: node.children[0].value,
        children: [],
        ref: headingRef
      })
      properties.id = nameToId(node.children[0].value)
      properties.ref = headingRef
      isHeading = true
    }

    else if (node.type === "element" && node.tagName === "h2") {
      toc[toc.length - 1].children.push({
        heading: node.children[0].value,
        ref: headingRef
      })
      properties.id = nameToId(node.children[0].value)
      properties.ref = headingRef
      isHeading = true
    }

    let { dataLanguage, ...domProps } = properties

    element = React.createElement(
      node.tagName || "div",
      domProps,
      ...children
    )
  }

  return element
}

export default renderHtmlAst
