import React from "react"

import { nameToId } from "../utils"
import { ITableOfContents } from "../types"

interface Props {
  toc: ITableOfContents
}

const TableOfContents: React.FC<Props> = ({ toc }) => {
  const [activeMenu, setActiveMenu] = React.useState<string>()
  const [activeSubsection, setActiveSubsection] = React.useState<string>()

  const createClickHandler = (id: string, ref: React.RefObject<HTMLElement>) => {
    return (evt: React.SyntheticEvent<HTMLAnchorElement>) => {
      evt.preventDefault()
      setActiveMenu(id)
      setActiveSubsection(undefined)
      ref.current.scrollIntoView()
    }
  }

  const createSubsectionClickHandler = (id: string, ref: React.RefObject<HTMLElement>) => {
    return (evt: React.SyntheticEvent<HTMLAnchorElement>) => {
      evt.preventDefault()
      setActiveSubsection(id)
      ref.current.scrollIntoView()
    }
  }

  return (
    <ul id="toc" className="toc-list-h1">
      {toc.map(section => {
        const id = nameToId(section.heading)
        const isActive = activeMenu === id ? true : false
        const style = {
          display: activeMenu === id ? "block" : "none",
        }
        return (
          <li key={id}>
            <a
              className={"toc-h1 toc-link" + (isActive ? " active" : "")}
              href={"#" + id}
              onClick={createClickHandler(id, section.ref)}
            >
              {section.heading}
            </a>
            <ul
              className={"toc-list-h2" + (isActive ? " active" : "")}
              style={style}
            >
              {section.children.map(subsection => {
                const subsectionId = nameToId(subsection.heading)
                const isSubsectionActive =
                  subsectionId === activeSubsection ? true : false
                return (
                  <li key={subsection.heading}>
                    <a
                      onClick={createSubsectionClickHandler(subsectionId, subsection.ref)}
                      className={
                        "toc-h2 toc-link" +
                        (isSubsectionActive ? " active" : "")
                      }
                      href={"#" + subsectionId}
                    >
                      {subsection.heading}
                    </a>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )
}

export default TableOfContents
