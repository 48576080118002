import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"

import TableOfContents from "../components/TableOfContents"
import logo from "../images/logo.png"

import { ITableOfContents } from "../types"
import renderHtmlAst from "../lib/renderHtmlAst"

// global css
import "../styles/api.css"
require("prismjs/themes/prism-tomorrow.css")

const Root = styled.div``
const LogoImg = styled.img`
  width: 100px;
  padding: 1rem;
`

export default function Api({ data }) {
  const [language, setLanguage] = React.useState("python")
  const [renderedHtml, setRenderedHtml] = React.useState()
  const [tocData, setTocData] = React.useState<ITableOfContents>()

  React.useEffect(() => {
    let toc = []
    const ast = data.allMarkdownRemark.edges[0].node.htmlAst
    const html = renderHtmlAst(ast, language, toc)
    setRenderedHtml(html)
    setTocData(toc)
  }, [language])

  if (!renderedHtml) {
    return <>Rendering..</>
  }

  return (
    <Root>
      <Helmet title={"Morta API Reference"} />
      <div className="toc-wrapper">
        <Link to={"/"}><LogoImg src={logo} /></Link>
        {tocData && <TableOfContents toc={tocData} />}
      </div>
      <div className="page-wrapper">
        <div className="dark-box"></div>
        {renderedHtml}
        <div className="dark-box"></div>
      </div>
    </Root>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/.*/api.md$/" } }
    ) {
      totalCount
      edges {
        node {
          id
          html
          htmlAst
        }
      }
    }
  }
`
